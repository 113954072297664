<template>
  <div
    v-if="showModal"
    class="modal-overlay"
    :class="{ 'loading-overlay': isLoading }"
  >
    <div class="modal-container">
      <div class="matc-dialog-header">
        <span class="matc-dialog-header-title">
          {{ title }} <strong>{{ subtitle }}</strong>
        </span>
      </div>
      <div class="matc-dialog">
        <div class="matc-dialog-content">
          <slot></slot>
          <div class="MatcButtonBarDialog MatcMarginTop">
            <a @click="close" class="MatcLinkButtonDialog" v-if="!isLoading">{{
              cancelLabel
            }}</a>
            <a
              id="confirm"
              :class="[
                'MatcButtonDialog',
                { MatcButtonRed: labelType === 'danger', loading: isLoading },
              ]"
              @click="confirm"
              :disabled="isLoading"
            >
              <span v-if="isLoading" class="spinner"></span>
              {{ confirmLabel }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 600px;
  position: relative;
}

.input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.MatcButtonBar {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .MatcLinkButtonDialog {
    background: none;
    border: none;
    color: var(--primary-color);
    cursor: pointer;
  }

  .MatcButtonDialog {
    background: var(--primary-color);
    border: none;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #0056b3;
    }
  }
}

.MatcButtonDialog {
  display: inline-flex;
  padding: 8px 24px;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  background-color: var(--color-primary);
  border-color: transparent;
  color: #fff;
  align-items: center;
}

.MatcButtonDialog:hover {
  color: #fff;
  background: var(--color-primary-hover);
}

.MatcLinkButtonDialog {
  border-radius: 10px;
  padding: 8px 16px;
  cursor: pointer;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 700;
  background: #f1f1f1;
  align-content: center;
}

.MatcLinkButtonDialog:hover {
  background: #e7e7e7;
  color: #333;
}

.MatcErrorLabel {
  color: red;
  margin-top: 10px;
}

.modal-overlay.loading-overlay {
  pointer-events: none;
  opacity: 0.5;
}
.matc-dialog {
  padding: 25px;
}
.matc-dialog-header {
  padding: 15px;
  border-bottom: 1px solid #d5d5d5;
}
.matc-dialog-header-title {
  font-size: 20px;
}
.loading {
  position: relative;
  pointer-events: none;
  opacity: 0.6;
}

.MatcButtonBarDialog {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.spinner {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 2px solid #fff;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 640px) {
  .modal-container {
    min-width: auto;
    width: calc(100% - 80px);
  }

}
</style>

<script>
export default {
  name: "BaseDialog",
  props: ["title", "subtitle", "minwidth", "labels"],
  data() {
    return {
      showModal: false,
      confirmLabel: (this.labels && this.labels.confirm) || "Save",
      cancelLabel: (this.labels && this.labels.cancel) || "Cancel",
      labelType: this.labels && this.labels.type,
      isLoading: false,
    };
  },
  methods: {
    close() {
      if (!this.isLoading) {
        this.showModal = false;
      }
    },
    show() {
      this.showModal = true;
    },
    async confirm() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.$emit("confirmAction");
        this.isLoading = false;
      }
    },
    setLoading(loading) {
      this.isLoading = loading;
    },
  },
};
</script>
