const video = {
  id: "video",
  title: "Video AI",
  icon: "mdi mdi-video",
  logo: "ai-video",
  brands: [
    { id: "fal", label: "Fal", logo: "ai-fal" },
  ],
  models: [    
    {
      id: "fal-runway",
      name: "Fal - Runway",
      url: "https://queue.fal.run/fal-ai/runway-gen3/turbo/image-to-video",
      headers: [{ key: "Content-Type", value: "application/json" }],
      type: "fal",
      elements: [
        {
          type: "TextArea",
          required: true,
          id: "prompt",
          label: "Prompt",
          placeholder: "Describe the image you want to generate",
        },
        {
          type: "file",
          required: true,
          id: "image_url",
          label: "Image URL",
          helper: "Upload an image file (JPEG, WEBP, PNG only)",
        },
      ],
      advanced: [
        {
          type: "Number",
          id: "duration",
          label: "Duration",
          description: "The duration of the generated video in seconds Default value: 5",
          default: 5,
        },       
      ],
      method: "POST",
      authType: "Key",
      documentationAuthLink:
        "https://fal.ai/models/fal-ai/flux/dev/image-to-image/api",
      getTemplate: (vars) => {
        return {
          ...vars,
        };
      },
      output: {
        path: "video.url",
        type: "JSON",
      },
      secondjobcall: {
        url: "https://queue.fal.run/fal-ai/runway-gen3/requests/$REQUEST_ID",
        headers: [{ key: "Content-Type", value: "application/json" }],
        method: "GET",
        token: "",
        input: {
          type: "JSON",
        },
        output: {
          type: "JSON",
          path: "video.url",
        },
        authType: "Key",
      },
    },
  ],
};

export default video;
