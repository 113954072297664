<template>
  <div class="table-container">
    <table class="data-table">
      <thead>
        <tr>
          <th
            v-for="column in columns"
            :key="column.key"
            :class="['table-header', column.headerClass]"
          >
            {{ column.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="paginatedData.length === 0">
          <td :colspan="columns.length" class="table-cell empty-message">
            {{ emptyMessage }}
          </td>
        </tr>
        <tr v-for="(row, index) in paginatedData" :key="index">
          <td
            v-for="column in columns"
            :key="column.key"
            :class="['table-cell', column.cellClass]"
          >
            <span v-if="typeof row[column.key] === 'object'">
              <a
                v-if="row[column.key].type === 'link'"
                :href="row[column.key].url"
                target="_blank"
                class="action-link"
              >
                {{ row[column.key].label }}
              </a>
              <button
                v-else-if="row[column.key].type === 'button'"
                @click="row[column.key].onClick"
                class="action-button"
              >
                {{ row[column.key].label }}
              </button>
            </span>
            <span v-else>
              {{ row[column.key] }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Pagination Controls -->
    <div v-if="totalPages > 1" class="pagination-container">
      <button
        class="pagination-button"
        :disabled="currentPage === 1"
        @click="goToPreviousPage"
      >
        Previous
      </button>

      <button
        v-for="page in totalPages"
        :key="page"
        class="pagination-button"
        :class="{ active: currentPage === page }"
        @click="goToPage(page)"
      >
        {{ page }}
      </button>

      <button
        class="pagination-button"
        :disabled="currentPage === totalPages"
        @click="goToNextPage"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: Array,
    data: Array,
    emptyMessage: {
      type: String,
      default: "No data available",
    },
    rowsPerPage: {
      type: Number,
      default: 5, // Default to 5 rows per page
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.data.length / this.rowsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.data.slice(start, end);
    },
  },
  methods: {
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
  watch: {
    data() {
      // Reset to first page if data changes
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
/* Container */
.table-container {
  overflow-x: auto;
  width: 100%;
  margin: 16px 0;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

/* Table */
.data-table {
  width: 100%;
  border-collapse: collapse;
}

/* Table Header */
.table-header {
  padding: 12px 16px;
  text-align: left;
  font-weight: 600;
  font-size: 0.875rem;
  color: #555;
  border-bottom: 1px solid #e0e0e0;
  background-color: #fafafa;
}

/* Table Cell */
.table-cell {
  padding: 12px 16px;
  font-size: 0.875rem;
  border-bottom: 1px solid #e0e0e0;
}

tr:last-child .table-cell {
  border-bottom: 0px;
}

/* Empty Message */
.table-cell.empty-message {
  text-align: center;
  color: #999;
  padding: 24px;
}

/* Table Row Hover */
.data-table tbody tr:hover {
  background-color: #f5f5f5;
}

/* Zebra Striping */
.data-table tbody tr:nth-child(odd) {
  background-color: #fcfcfc;
}

/* Action Links and Buttons */
.action-link,
.action-button {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
}

.action-link:hover,
.action-button:hover {
  text-decoration: underline;
}

.action-button:focus {
  outline: none;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .table-header,
  .table-cell {
    padding: 8px 12px;
    font-size: 0.8125rem;
  }
}

/* Pagination Container */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

/* Pagination Buttons */
.pagination-button {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  color: #333333;
  padding: 6px 12px;
  margin: 0 4px;
  font-size: 0.875rem;
  cursor: pointer;
  border-radius: 4px;
  min-width: 36px;
}

.pagination-button:hover {
  background-color: #f0f0f0;
}

.pagination-button:disabled {
  color: #999999;
  cursor: not-allowed;
}

.pagination-button.active {
  background-color: var(--primary-color);
  color: #ffffff;
  border-color: var(--primary-color);
}

.pagination-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}
</style>
